const MapboxGeocoder = require('@mapbox/mapbox-sdk/services/geocoding')
import { mapboxAccessToken } from '@/constants'

export function normalizeLatLong(value) {
  // Determine the range of the original value
  const range = value >= -180 && value <= 180 ? 180 : 360

  // Add or subtract 180 degrees as necessary to get a value within the range of -90 to 90
  while (value < -90 || value > 90) {
    if (value < -90) {
      value += range
    } else if (value > 90) {
      value -= range
    }
  }

  // Return the normalized value
  return value
}

/**
 * calcuate speed form last 2 data
 * @param {Object} p1 data of second to last
 * @param {Object} p2 data of last point
 * @returns speed
 */
export function calculateSpeed(p1, p2) {
  const lat1 = p1.latlng.split(',')[0]
  const long1 = p1.latlng.split(',')[1]
  const ts1 = p1.ts
  const lat2 = p2.latlng.split(',')[0]
  const long2 = p2.latlng.split(',')[1]
  const ts2 = p2.ts
  // Calculate the distance between the two points using the Haversine formula
  const R = 6371 // Radius of the earth in km
  const dLat = toRad(lat2 - lat1)
  const dLong = toRad(long2 - long1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c

  // Calculate the time difference between the two positions in hours
  const timeDiff = Math.abs(ts2 - ts1) / (1000 * 60 * 60)

  // Calculate the speed in km/h
  const speed = distance / timeDiff

  return speed
}

function toRad(degrees) {
  return (degrees * Math.PI) / 180
}

const geocoder = MapboxGeocoder({ accessToken: mapboxAccessToken })

/**
 * Geocodes the provided address to get its latitude and longitude.
 * @param {string} address The address to geocode.
 * @returns {Promise<{ lat: number, long: number }>} A promise that resolves to an object containing the latitude and longitude of the geocoded address.
 * @throws {Error} Throws an error if the geocoding fails or the address is invalid.
 */
export async function geocode(address) {
  const response = await geocoder
    .forwardGeocode({
      query: address,
      limit: 1,
    })
    .send()

  if (response.body.features.length === 0) {
    throw new Error('Address not found. Please provide a valid address.')
  }

  const coordinates = response.body.features[0].geometry.coordinates
  return { lat: coordinates[1], long: coordinates[0] }
}

/**
 * Checks if the provided address is a valid USA address.
 *
 * @param {string} address The address to validate.
 * @returns {boolean} True if the address is a valid USA address, false otherwise.
 */
export function validateAddress(address) {
  const usaAddressRegex =
    /^\d+\s+.*?,\s*(?:\w+\s*)+,\s*[A-Za-z]+,?\s+\d{5}(?:-\d{4})?$/

  return usaAddressRegex.test(address)
}

/**
 * Compare two time strings in the format 'HH:MM'
 * @param {string} timeA - The first time string
 * @param {string} timeB - The second time string
 * @return {number} - Negative if timeA is earlier than timeB, positive if timeA is later than timeB, 0 if they are equal
 */
export function compareTimes(timeA, timeB) {
  const [hoursA, minutesA] = timeA.split(':').map(Number)
  const [hoursB, minutesB] = timeB.split(':').map(Number)

  // Convert the hours and minutes to total minutes for comparison
  const totalMinutesA = hoursA * 60 + minutesA
  const totalMinutesB = hoursB * 60 + minutesB

  return totalMinutesA - totalMinutesB
}

export function adjustTime(timeStr, minutesToAdd) {
  const [hours, minutes] = timeStr.split(':').map(Number)

  const now = new Date()
  const date = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hours,
    minutes,
  )

  date.setMinutes(date.getMinutes() + Number(minutesToAdd))

  // Format the hours and minutes back to "HH:mm"
  const adjustedHours = date.getHours().toString().padStart(2, '0')
  const adjustedMinutes = date.getMinutes().toString().padStart(2, '0')

  return `${adjustedHours}:${adjustedMinutes}`
}
