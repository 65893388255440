import { getAutomations } from '@/services/api/automation/automation'

export default {
  namespaced: true,
  state: {
    automations: [],
  },
  mutations: {
    SET_AUTOMATIONS(state, payload) {
      state.automations = payload
    },
  },
  actions: {
    async getAutomations({ commit }, payload) {
      return getAutomations(payload)
        .then((response) => {
          const sortedAutomations = response.data.data

          commit('SET_AUTOMATIONS', sortedAutomations)
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  getters: {
    automations: (state) => state.automations,
  },
}
