import apiClient from '@/services/axios'

export async function listAllCompanies() {
  return apiClient.get('/companies')
}

export async function getCompanyByID(id) {
  try {
    const response = await apiClient.get(`/companies/${id}`)
    if (response.data) {
      return {
        success: true,
        status: 200,
        message: 'Company data',
        data: response.data,
      }
    } else {
      return {
        success: false,
        status: 404,
        data: {},
        message: `Company with id: ${id} not found!`,
      }
    }
  } catch (error) {
    return {
      success: false,
      status: 500,
      message: 'Error fetching Company by ID',
      error,
    }
  }
}

export function createCompany(company) {
  return apiClient.post('/companies', company)
}

export async function uploadLogo(companyId, file) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('id', companyId);

    return await apiClient.post('/companies/uploadPfp', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
}

export async function updateCompany(id, companyData) {
  return apiClient.patch(`/companies/${id}`, companyData)
}

export async function deleteCompany(id) {
  return apiClient.delete(`/companies/${id}`)
}
