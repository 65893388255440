import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: to => {
        if (store.state.user.payload) {
          if (store.state.user.payload['cognito:groups'].includes('Gaurdian')) {
            return { path: 'parentportal/welcomeparents' }
          } else {
            return { path: '/tools/contracts/all' }
          }
        } else {
          return { path: '/tools/contracts/all' }
        }
      },
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // Dashboards
        {
          path: '/dashboard',
          meta: {
            title: 'Dashboard',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/dashboard/New'),
        },
        // Analytics
        {
          path: '/analytics',
          meta: {
            title: 'Analytics',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/analytics'),
        },
        // Parents Portal
        {
          path: '/parentportal/welcomeparents',
          meta: {
            title: 'Welcome',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/parentportal/welcomeparents'),
        },
        {
          path: '/parentportal/students',
          meta: {
            title: 'My Students',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/parentportal/students'),
        },
        {
          path: '/parentportal/profile',
          meta: {
            title: 'My Profile',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/parentportal/profile'),
        },
        {
          path: '/parentportal/alerts',
          meta: {
            title: 'Alerts',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/parentportal/alerts'),
        },
        //Schematic
        {
          path: '/schematic',
          meta: {
            title: 'Schematic',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/schematic'),
        },
        // Tools
        {
          path: '/tools/students/all',
          meta: {
            title: 'All Students',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/allstudents'),
        },
        {
          path: '/tools/buildings/all',
          meta: {
            title: 'All Buildings',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/allbuildings'),
        },
        {
          path: '/tools/student/view',
          meta: {
            title: 'Student',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/student'),
        },
        {
          path: '/tools/employees/all',
          meta: {
            title: 'All Employees',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/allemployees'),
        },
        {
          path: '/tools/employee/view',
          meta: {
            title: 'Employee',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/employee'),
        },
        {
          path: '/tools/vehicles/all',
          meta: {
            title: 'All Vehicles',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/vehicles'),
        },
        {
          path: '/tools/vehicle/view',
          meta: {
            title: 'Vehicle',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/vehicle'),
        },
        {
          path: '/tools/contracts/all',
          meta: {
            title: 'All Contracts',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/contracts'),
        },
        {
          path: '/tools/contract/view',
          meta: {
            title: 'Contract',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/contract'),
        },
        {
          path: '/tools/districts/all',
          meta: {
            title: 'All Districts',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/districts'),
        },
        {
          path: '/tools/district/view',
          meta: {
            title: 'District',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/district'),
        },
        {
          path: '/tools/routes/all',
          meta: {
            title: 'All Routes',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/routing/routing'),
        },
        {
          path: '/tools/telematics/all',
          meta: {
            title: 'All Telematics',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/telematics'),
        },
        {
          path: '/tools/telematics/report/view',
          meta: {
            title: 'Report',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/tools/report'),
        },
        // Preferences
        {
          path: '/preferences/profile',
          meta: {
            title: 'Profile',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/preferences/profile'),
        },
        {
          path: '/preferences/settings',
          meta: {
            title: 'Settings',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/preferences/settings'),
        },
        {
          path: '/preferences/companyprofile',
          meta: {
            title: 'Company Profile',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/preferences/companyprofile'),
        },
        {
          path: '/preferences/organizationprofile',
          meta: {
            title: 'Organization Profile',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/preferences/organizationprofile'),
        },
        {
          path: '/preferences/devices',
          meta: {
            title: 'All Devices',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/preferences/devices'),
        },
        {
          path: '/preferences/facilities',
          meta: {
            title: 'All Facilities',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/preferences/facilities'),
        },
        // Apps
        {
          path: '/calendar',
          meta: {
            title: 'Apps / Calendar',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/apps/calendar/NewCalender'),
        },
        // Admin
        {
          path: '/admin3366',
          meta: {
            title: 'Administration',
            authRequired: true,
            hidden: true,
          },
          component: () => import('./views/admin'),
        },
      ],
    },
    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      meta: {
        authRequired: false,
        hidden: false,
      },
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/2fa',
          meta: {
            title: 'Two-Step Verification',
          },
          component: () => import('./views/auth/2FA'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/forgot-password-resend',
          meta: {
            title: 'Forgot Password Resend',
          },
          component: () => import('./views/auth/forgot-password-resend'),
        },
        {
          path: '/auth/forgot-password-submit',
          meta: {
            title: 'Forgot Password Submit',
          },
          component: () => import('./views/auth/forgot-password-submit'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },
    {
      path: '/onboarding',
      component: () => import('./views/onboarding'),
      meta: {
        title: 'Onboarding',
        authRequired: false,
        hidden: false,
      },
    },
    // Redirect to 404
    {
      path: '*',
      redirect: 'auth/404',
      hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
