import apiClient from '@/services/axios'

export async function listAllTelematics() {
  return apiClient.get(`/telematics`)
}

export async function findOneTelematic(imeiNumber) {
  return await apiClient.get(`/telematics/${imeiNumber}`)
}

export async function getTelematicsByFilter(filter) {
  const {
    deviceId,
    startDate,
    endDate,
    startTime,
    endTime,
    timeInterval,
    utcOffset,
  } = filter
  return await apiClient.get(
    `/telematics/${deviceId}/${startDate}/${endDate}/${startTime}/${endTime}/${timeInterval}/${utcOffset}`,
  )
}

export async function getAddressWithGeocode(batch) {
  return await apiClient.post('/telematics/geocode', { geocodes: batch })
}

export async function getDrivingHistoryByFilter(records) {
  if (!records || records.length === 0) return null

  const startTime = Math.floor(new Date(records[0].timestamp).getTime() / 1000)
  const endTime = Math.floor(
    new Date(records[records.length - 1].timestamp).getTime() / 1000,
  )
  const imei = records[0].imei

  return await apiClient.get(`/driving-history/${imei}/${startTime}/${endTime}`)
}
