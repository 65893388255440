import apiClient from '@/services/axios'

export function getReports(query) {
  return apiClient.get(`/reports`, {
    params: query,
  })
}

export function getReportById(id) {
  return apiClient.get(`/reports/${id}`)
}

export function getReportsByAutomationId(id) {
  return apiClient.get(`/reports/automation/${id}`)
}

export function deleteReport(id) {
  return apiClient.delete(`/reports/${id}`)
}
