<template>
  <div id="app">
    <div v-if="offline" class="offlineMessage">
      You are offline. Any changes you make will be effective when you go back
      online.
    </div>
    <!-- <div v-else class="onlineMessage">You are online</div> -->
    <localization></localization>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Localization from '@/localization'
import { logout } from '@/services/auth'

export default {
  name: 'app',
  components: { Localization },
  data() {
    return {
      offline: undefined,
    }
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('user', ['authorized']),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    },
  },
  mounted() {
    console.log('mounted')
    this.$store.dispatch('user/LOAD_CURRENT_ACCOUNT')
    this.$store.commit('SET_THEME', { theme: this.settings.theme })
  },
  watch: {
    '$store.state.settings.theme'(theme) {
      this.$store.commit('SET_THEME', { theme })
    },
    authorized(authorized) {
      // When a user opens the site and was already logged in on the browser,
      // we show the dashboard instead of the login screen.
      // Before adding the condition where currentRoute === '/', this code did not work even when opening
      // the page localhost:8080/#/auth/login. The variable this.currentRoute was '/' even in that case.
      // We should figure out why this is the case.
      if (
        authorized &&
        (this.currentRoute === '/auth/login' || this.currentRoute === '/')
      ) {
        // this.$router.replace('/tools/contracts/all')
        this.$router.replace(this.nextRoute)
      }
    },
    $route(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  methods: {
    async logout() {
      try {
        await logout()
      } catch (error) {
        alert(error.message)
      }
    },
  },
}
</script>

<style>
.offlineMessage {
  background-color: rgb(104, 185, 104);
}

.c-h {
  cursor: pointer;
}

/*SWAL UI*/
.swal2-confirm {
  border-radius: 6px !important;
  background: #152f4a !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  letter-spacing: 1.1px !important;
  -webkit-box-shadow: 0px 0px 10px rgb(21 47 74 / 10%) !important;
  box-shadow: 0px 0px 10px rgb(21 47 74 / 10%) !important;
  color: #ffffff !important;
}

.swal2-cancel {
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  letter-spacing: 1.1px !important;
  -webkit-box-shadow: 0px 0px 10px rgb(21 47 74 / 10%) !important;
  box-shadow: 0px 0px 10px rgb(21 47 74 / 10%) !important;
  color: #ffffff !important;
}
</style>
