import {
  getMapboxStatistics,
  getRoutingStatistics,
  getStudentStatistics,
  getVehicleStatistics,
  getFuelPricesStatistics,
} from '@/services/api/analytics/analytics'

export default {
  namespaced: true,
  state: {
    routingStatistics: null,
    navigationStatistics: [],
    mapsStatistics: [],
    studentStatistics: null,
    studentStatisticsMetaData: null,
    vehicleStatistics: null,
    vehicleStatisticsMetaData: null,
    fuelPricesStatistics: null,
    loading: false,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ROUTING_STATISTICS(state, payload) {
      state.routingStatistics = payload
    },
    SET_NAVIGATION_STATISTICS(state, payload) {
      state.navigationStatistics = payload
    },
    SET_MAPS_STATISTICS(state, payload) {
      state.mapsStatistics = payload
    },
    SET_STUDENT_STATISTICS(state, payload) {
      state.studentStatistics = payload
    },
    SET_STUDENT_STATISTICS_META_DATA(state, payload) {
      state.studentStatisticsMetaData = payload
    },
    SET_VEHICLE_STATISTICS(state, payload) {
      state.vehicleStatistics = payload
    },
    SET_VEHICLE_STATISTICS_META_DATA(state, payload) {
      state.vehicleStatisticsMetaData = payload
    },
    SET_FUEL_PRICES_STATISTICS(state, payload) {
      state.fuelPricesStatistics = payload
    },
  },
  actions: {
    async getRoutingStatistics({ commit }, payload) {
      commit('SET_LOADING', true)
      const response = await getRoutingStatistics(payload)
      const chartData = calculateRoutingStatisticData(response.data)
      commit('SET_ROUTING_STATISTICS', chartData)
      commit('SET_LOADING', false)
    },
    async getMapboxStatistics({ commit }, payload) {
      commit('SET_LOADING', true)
      const response = await getMapboxStatistics(payload)
      const chartData = calculateMapboxStatisticData(response.data)
      commit('SET_NAVIGATION_STATISTICS', chartData.navigationStatistics)
      commit('SET_MAPS_STATISTICS', chartData.mapsStatistics)
      commit('SET_LOADING', false)
    },
    async getStudentStatistics({ commit }, payload) {
      commit('SET_LOADING', true)
      const response = await getStudentStatistics(payload)
      const chartData = calculateStudentStatisticData(
        response.data.studentStatistics,
      )
      commit('SET_STUDENT_STATISTICS', chartData)
      commit('SET_STUDENT_STATISTICS_META_DATA', response.data.metadata)
      commit('SET_LOADING', false)
    },
    async getVehicleStatistics({ commit }, payload) {
      commit('SET_LOADING', true)
      const response = await getVehicleStatistics(payload)
      const chartData = calculateVehicleStatisticData(
        response.data.vehicleStatistics,
      )
      commit('SET_VEHICLE_STATISTICS', chartData)
      commit('SET_VEHICLE_STATISTICS_META_DATA', [response.data.metadata])
      commit('SET_LOADING', false)
    },
    async getFuelPricesStatistics({ commit }, payload) {
      commit('SET_LOADING', true)
      const response = await getFuelPricesStatistics(payload)
      const chartData = calculateFuelPricesStatisticData(response.data)
      commit('SET_FUEL_PRICES_STATISTICS', chartData)
      commit('SET_LOADING', false)
    },
  },
  getters: {},
}

function calculateRoutingStatisticData(routingStatistics) {
  let xValues = ['x']
  let totalRoutesData = {
    groupTitle: 'General',
    title: 'Total Routes',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'routes',
  }

  let totalRouteLengthData = {
    groupTitle: 'General',

    title: 'Total Route Length',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'hours',
  }

  let avgRouteLengthData = {
    groupTitle: 'General',

    title: 'Avg. Route Length',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'mins',
  }

  let totalRoutingDistanceData = {
    groupTitle: 'General',

    title: 'Total Distance',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'miles',
  }

  let avgRoutingDistanceData = {
    groupTitle: 'General',

    title: 'Avg. Route Distance',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'miles',
  }

  let totalRidershipData = {
    groupTitle: 'General',

    title: 'Total Ridership',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'students',
  }

  let avgRidershipData = {
    groupTitle: 'General',

    title: 'Avg. Ridership',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'students',
  }

  let totalStopCountData = {
    groupTitle: 'General',

    title: 'Total Stops Count',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'stops',
  }

  let avgStopCountData = {
    groupTitle: 'General',

    title: 'Avg. Stops Count',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'stops',
  }

  for (let i = 0; i < routingStatistics.length; i++) {
    const dayStas = routingStatistics[i]
    xValues.push(dayStas.date)

    totalRoutesData.yValues[0].push(dayStas.routingCount)
    totalRoutesData.yValues[1].push(dayStas.specializedRoutingCount)

    totalRouteLengthData.yValues[0].push(
      (dayStas.totalRoutingsLength / 60).toFixed(2),
    )
    totalRouteLengthData.yValues[1].push(
      (dayStas.totalSpecializedRoutingsLength / 60).toFixed(2),
    )

    avgRouteLengthData.yValues[0].push(dayStas.avgRoutingsLength.toFixed(2))
    avgRouteLengthData.yValues[1].push(
      dayStas.avgSpecializedRoutingsLength.toFixed(2),
    )

    totalRoutingDistanceData.yValues[0].push(
      (dayStas.totalRoutingDistance * 0.000621371192).toFixed(2),
    )
    totalRoutingDistanceData.yValues[1].push(
      (dayStas.totalSpecializedRoutingDistance * 0.000621371192).toFixed(2),
    )

    avgRoutingDistanceData.yValues[0].push(
      (dayStas.avgRoutingDistance * 0.000621371192).toFixed(2),
    )
    avgRoutingDistanceData.yValues[1].push(
      (dayStas.avgSpecializedRoutingDistance * 0.000621371192).toFixed(2),
    )

    totalRidershipData.yValues[0].push(dayStas.routingStudents)
    totalRidershipData.yValues[1].push(dayStas.specializedRoutingStudents)

    avgRidershipData.yValues[0].push(dayStas.avgRoutingStudents.toFixed(2))
    avgRidershipData.yValues[1].push(
      dayStas.avgSpecializedRoutingStudents.toFixed(2),
    )

    totalStopCountData.yValues[0].push(dayStas.routingStopCount)
    totalStopCountData.yValues[1].push(dayStas.specializedRoutingStopCount)

    avgStopCountData.yValues[0].push(dayStas.avgRoutingStopCount.toFixed(2))
    avgStopCountData.yValues[1].push(
      dayStas.avgSpecializedRoutingStopCount.toFixed(2),
    )

    if (i === routingStatistics.length - 1) {
      totalRoutesData.total =
        dayStas.routingCount + dayStas.specializedRoutingCount
      totalRouteLengthData.total = (
        (dayStas.totalRoutingsLength + dayStas.totalSpecializedRoutingsLength) /
        60
      ).toFixed(2)
      avgRouteLengthData.total = (
        (dayStas.avgRoutingsLength + dayStas.avgSpecializedRoutingsLength) /
        2
      ).toFixed(2)
      totalRoutingDistanceData.total = (
        (dayStas.totalRoutingDistance +
          dayStas.totalSpecializedRoutingDistance) *
        0.000621371192
      ).toFixed(2)
      avgRoutingDistanceData.total = (
        ((dayStas.avgRoutingDistance + dayStas.avgSpecializedRoutingDistance) *
          0.000621371192) /
        2
      ).toFixed(2)
      totalRidershipData.total =
        dayStas.routingStudents + dayStas.specializedRoutingStudents
      avgRidershipData.total = (
        (dayStas.avgRoutingStudents + dayStas.avgSpecializedRoutingStudents) /
        2
      ).toFixed(2)
      totalStopCountData.total =
        dayStas.routingStopCount + dayStas.specializedRoutingStopCount
      avgStopCountData.total = (
        (dayStas.avgRoutingStopCount + dayStas.avgSpecializedRoutingStopCount) /
        2
      ).toFixed(2)
    }
  }

  totalRoutesData.xValues = xValues
  totalRouteLengthData.xValues = xValues
  avgRouteLengthData.xValues = xValues
  totalRoutingDistanceData.xValues = xValues
  avgRoutingDistanceData.xValues = xValues
  avgRidershipData.xValues = xValues
  totalRidershipData.xValues = xValues
  avgStopCountData.xValues = xValues
  totalStopCountData.xValues = xValues

  return [
    totalRoutesData,
    totalRoutingDistanceData,
    avgRoutingDistanceData,
    totalRouteLengthData,
    avgRouteLengthData,
    totalRidershipData,
    avgRidershipData,
    totalStopCountData,
    avgStopCountData,
  ]
}

function calculateMapboxStatisticData(statistics) {
  let xValues = ['x']
  let totalDirectionsAPIData = {
    groupTitle: 'Navigation',
    title: 'Directions',
    xValues: ['x'],
    yValues: [['Directions API']],
    total: 0,
    unit: 'requests',
  }

  let totalMapLoadsForWebData = {
    groupTitle: 'Maps',
    title: 'Map Loads for Web',
    xValues: ['x'],
    yValues: [['Map Loads']],
    total: 0,
    unit: 'loads',
  }

  for (let i = 0; i < statistics.directionsAPIStatisticsData.length; i++) {
    const dayStas = statistics.directionsAPIStatisticsData[i]
    xValues.push(dayStas.date)

    totalDirectionsAPIData.yValues[0].push(dayStas.count)
    totalDirectionsAPIData.total += dayStas.count
  }

  for (let i = 0; i < statistics.mapLoadsForWebStatisticsData.length; i++) {
    const dayStas = statistics.mapLoadsForWebStatisticsData[i]

    totalMapLoadsForWebData.yValues[0].push(dayStas.count)
    totalMapLoadsForWebData.total += dayStas.count
  }

  totalDirectionsAPIData.xValues = xValues
  totalMapLoadsForWebData.xValues = xValues

  return {
    navigationStatistics: [totalDirectionsAPIData],
    mapsStatistics: [totalMapLoadsForWebData],
  }
}

function calculateStudentStatisticData(data) {
  let xValues = ['x']
  let totalStudentData = {
    groupTitle: 'General',
    title: 'Total Students',
    xValues: ['x'],
    yValues: [['Students']],
    total: 0,
    unit: 'students',
  }

  for (let i = 0; i < data.length; i++) {
    const dayStas = data[i]
    xValues.push(dayStas.date)

    totalStudentData.yValues[0].push(dayStas.totalStudents)

    if (i === data.length - 1) {
      totalStudentData.total = dayStas.totalStudents
    }
  }

  totalStudentData.xValues = xValues

  return [totalStudentData]
}

function calculateVehicleStatisticData(data) {
  let xValues = ['x']
  let totalVehiclesData = {
    groupTitle: 'General',
    title: 'Total Vehicles',
    xValues: ['x'],
    yValues: [['Vehicles']],
    total: 0,
    unit: 'vehicles',
  }
  let avgVehicleAgeData = {
    groupTitle: 'General',
    title: 'Average Vehicle Age',
    xValues: ['x'],
    yValues: [['Vehicles']],
    total: 0,
    unit: 'years',
  }

  for (let i = 0; i < data.length; i++) {
    const dayStas = data[i]
    xValues.push(dayStas.date)

    totalVehiclesData.yValues[0].push(dayStas.totalVehicles)

    if (i === data.length - 1) {
      totalVehiclesData.total = dayStas.totalVehicles
    }

    avgVehicleAgeData.yValues[0].push(dayStas.avgVehicleAge.toFixed(2))

    if (i === data.length - 1) {
      avgVehicleAgeData.total = dayStas.avgVehicleAge.toFixed(2)
    }
  }

  totalVehiclesData.xValues = xValues
  avgVehicleAgeData.xValues = xValues

  return [totalVehiclesData, avgVehicleAgeData]
}

function calculateFuelPricesStatisticData(data) {
  let xValues = ['x']
  let gasolinePricesData = {
    groupTitle: 'Fuel',
    title: 'Reg. Gasoline',
    xValues: ['x'],
    yValues: [['Gasoline']],
    total: 0,
    unit: 'per gallon',
    metadata: [
      {
        name: 'State',
        data: data.gasolinePricesData.metadata,
      },
    ],
  }

  for (let i = 0; i < data.gasolinePricesData.data.length; i++) {
    const dayStas = data.gasolinePricesData.data[i]
    xValues.push(dayStas.date)

    gasolinePricesData.yValues[0].push(dayStas.price.toFixed(2))

    if (i === data.gasolinePricesData.data.length - 1) {
      gasolinePricesData.total = dayStas.price.toFixed(2)
    }
  }

  gasolinePricesData.xValues = xValues

  let dieselPricesData = {
    groupTitle: 'Fuel',
    title: 'Diesel',
    xValues: ['x'],
    yValues: [['Diesel']],
    total: 0,
    unit: 'per gallon',
    metadata: [
      {
        name: 'State',
        data: data.dieselPricesData.metadata,
      },
    ],
  }

  for (let i = 0; i < data.dieselPricesData.data.length; i++) {
    const dayStas = data.dieselPricesData.data[i]
    xValues.push(dayStas.date)

    dieselPricesData.yValues[0].push(dayStas.price.toFixed(2))

    if (i === data.dieselPricesData.data.length - 1) {
      dieselPricesData.total = dayStas.price.toFixed(2)
    }
  }

  dieselPricesData.xValues = xValues

  return [gasolinePricesData, dieselPricesData]
}
