import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    // layerVisibility: {
    //   routes: 'none',
    //   stops: 'visible',
    //   students: 'none',
    //   depots: 'none',
    //   districts: 'none',
    // },
    visibleLayers: ['Stops'],
    customMapLayers: [
      // 'routes',
      'Stops',
      'Students',
      'Depots',
      'Districts',
    ],
    routes: [],
    stops: [],
    students: [],
    depots: [],
    districts: [],
  },
  mutations: {
    SET_SHOW_STUDENTS(state, payload) {
      const { show } = payload
      state.showStudents = show
    },
    TOGGLE_MAP_VISIBLE_LAYERS(state, layers) {
      state.visibleLayers = layers
    },
    SET_DEPOTS(state, payload) {
      state.depots = payload
    },
    SET_MAP_PROPERTY(state, { data, property }) {
      // all properties will be lowercase, so just in case
      const lowercaseProperty = property.toLowerCase()
      state[lowercaseProperty] = data
    },
  },
  actions: {
    TOGGLE_VISIBLE_LAYERS(state, layers) {
      state.commit('TOGGLE_MAP_VISIBLE_LAYERS', layers)
    },
    // All "LOAD" actions are called once to set the inital map state
    async LOAD_STOPS(state) {
      state.commit('SET_MAP_PROPERTY', { data, property: 'stops' })
    },
    async LOAD_STUDENTS(state) {
      state.commit('SET_MAP_PROPERTY', { data, property: 'students' })
    },
    async LOAD_DEPOTS(state) {
      state.commit('SET_MAP_PROPERTY', { data, property: 'depots' })
    },
    async LOAD_DISTRICTS(state) {
      state.commit('SET_MAP_PROPERTY', { data, property: 'districts' })
    },
    // If we just want to load all of it
    async LOAD_ALL_MAP_DATA(state) {
      await state.dispatch('LOAD_STOPS')
      await state.dispatch('LOAD_STUDENTS')
      await state.dispatch('LOAD_DEPOTS')
      await state.dispatch('LOAD_DISTRICTS')
    },
  },
  getters: {
    getMapState: (state) => state,
    getLayersVisibility: (state) => state.layerVisibility,
    getVisibleLayers: (state) => state.visibleLayers,
    getCustomMapLayers: (state) => state.customMapLayers,
    getRoutes: (state) => state.routes,
    getStops: (state) => state.stops,
    getStudents: (state) => state.students,
    getDepots: (state) => state.depots,
    getDistricts: (state) => state.districts,
  },
}
