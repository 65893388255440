import apiClient from '@/services/axios'

export function getAutomations(query) {
  return apiClient.get(`/automations`, {
    params: query,
  })
}

export function getAutomationById(id) {
  return apiClient.get(`/automations/${id}`)
}

export function deleteAutomation(id) {
  return apiClient.delete(`/automations/${id}`)
}
