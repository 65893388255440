import { getReportsByAutomationId } from '@/services/api/report/report'

export default {
  namespaced: true,
  state: {
    reports: [],
  },
  mutations: {
    SET_REPORTS(state, payload) {
      state.reports = payload
    },
  },
  actions: {
    async getReportsByAutomationId({ commit }, payload) {
      return getReportsByAutomationId(payload)
        .then((response) => {
          const reports = response.data

          commit('SET_REPORTS', reports)
          return response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  getters: {
    reports: (state) => state.reports,
  },
}
