import apiClient from '@/services/axios'
import { listAllContract } from '../contract/contract'
import { validateAddress } from '@/utils'

/**
 * Create a district
 *
 * @param {District} district
 * @returns promise
 */
export function createDistrict(district) {
  return apiClient.post('/districts', district)
}

/**
 * Update a district
 *
 * @param {District} district
 * @returns
 */

export async function updateDistrict(id, districtData) {
  return apiClient.patch(`/districts/${id}`, districtData)
}

/**
 * delete a district
 * @param {ID} id
 * @returns
 */
export async function deleteDistrict(id) {
  return apiClient.delete(`/districts/${id}`)
}

export async function listAllDistricts() {
  return apiClient.get('/districts')
}

export async function getDistrictById(id) {
  return apiClient.get(`/districts/${id}`)
}

export async function updateDistrictRule(id, updatedData) {
  return apiClient.patch(`/districts/${id}/rules`, updatedData)
}

export async function uploadExistingDistrictData(data) {
  try {
    const response = await listAllContract()
    const contracts = response.data
    // check if the data is valid
    for (let i = 0; i < data.length; i++) {
      const valid = validateAddress(data[i].mailingAddress)
      if (!valid) {
        Vue.prototype.$notification.warning({
          message: `Row ${i + 1} mailing address is invalid`,
        })
        return
      }
    }
    for (let s = 0; s < data.length; s++) {
      const element = data[s]
      const matchingContract = contracts.find(
        (contract) => contract.contractName === element.assignedContract.trim(),
      )

      const toUploadData = {
        districtName: element.districtName.trim() || '',
        districtAbbr: element.districtAbbreviation.trim() || '',
        districtUrl: element.districtUrl ? element.districtUrl.trim() : '',
        districtICalUrl: element.districtICalUrl
          ? element.districtICalUrl.trim()
          : '',
        contractId: matchingContract ? matchingContract.id : '',
        ncesID: element.districtNCESID.trim() || '',
        mailingAddress: element.mailingAddress.trim() || '',
        maxRouteLength: [
          {
            gradeFrom: 'PK',
            gradeTo: '13',
            time: 90,
          },
        ],
        stopDistance: [
          {
            gradeFrom: 'PK',
            gradeTo: '13',
            feet: 1500,
          },
        ],
      }

      const uploadDistrictData = await createDistrict(toUploadData)

      if (s === data.length - 1) {
        return {
          status: 200,
          message: 'Districts uploaded successfully',
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}
